import type { GetAccountResponse } from '@pushly/models/lib/structs'
import { createContext, useContext, useReducer } from 'react'
import { AccountMfaConfig } from '@pushly/models/lib/structs/accounts'

type OrgContext = {
    org: GetAccountResponse
}

export const OrgContext = createContext<OrgContext>(null!)

type OrgContextReducerAction = {
    type: 'patch_mfa'
    value: AccountMfaConfig
}

const handleOrgContextReducerRequest = (ctx: OrgContext, action: OrgContextReducerAction) => {
    switch (action.type) {
        case 'patch_mfa':
            return { org: ctx.org.clone({ mfa_config: action.value }) }
    }
}

export const useOrgContextWithReducer = () => {
    const { org } = useContext(OrgContext)
    return useReducer(handleOrgContextReducerRequest, { org })
}
