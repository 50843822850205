import * as React from 'react'
import { PageHeader } from '../../components/page-header/page-header'
import { AppService } from '../../services'
import { AccountDataForm } from '../../components/account-data-form/account-data-form'
import { Tabs } from 'antd'
import { UserList } from '../../components/user-list/user-list'
import { useLocation } from 'react-router-dom'
import { ProviderList } from '../../components/account-security-settings/sso-idp-list'
import { FEAT_ACCOUNT_SECURITY_TAB } from '../../constants'
import { ApiTokensDataView } from '../../components/api-tokens/api-tokens-data-view'
import { useService } from '@pushly/aqe/lib/hooks/use-service'
import { useCallback, useContext, useState } from 'react'
import { AccountMfaSettings } from 'components/account-mfa-settings/account-mfa-settings'
import { OrgContext } from '../../contexts/org-context'

const DEFAULT_TAB_KEY = 'settings'

export const Account: React.FC = () => {
    const appSvc = useService(AppService)
    const { org } = useContext(OrgContext)

    const { hash, pathname } = useLocation()
    const [currTabKey, setCurrTabKey] = useState<string>(hash?.replace('#', '').toLowerCase() || DEFAULT_TAB_KEY)

    const setNextTab = useCallback(
        (nextTabKey: string) => {
            if (nextTabKey !== currTabKey) {
                appSvc.route(`${pathname}#${nextTabKey}`)
                setCurrTabKey(nextTabKey)
            }
        },
        [currTabKey],
    )

    const accountSecurityTabEnabled = org?.flags?.includes(FEAT_ACCOUNT_SECURITY_TAB) || false

    return (
        <div className="sw-page single-account">
            <div className="sw-page-wrapper">
                <PageHeader title="Settings" append={<span>ID: {org.id}</span>} />

                <Tabs
                    size="small"
                    animated={{ inkBar: false, tabPane: false }}
                    tabPosition="top"
                    activeKey={currTabKey}
                    onChange={setNextTab}
                >
                    <Tabs.TabPane key="settings" tab="Settings">
                        <AccountDataForm key={`adf-${org.id}`} accountId={org.id} />
                    </Tabs.TabPane>

                    <Tabs.TabPane key="users" tab="Users">
                        <UserList key={`aul-${org.id}`} options={{ accountId: org.id }} />
                    </Tabs.TabPane>

                    <Tabs.TabPane key="api-tokens" tab="API Tokens">
                        <ApiTokensDataView level="account" id={org.id} />
                    </Tabs.TabPane>

                    {accountSecurityTabEnabled && (
                        <Tabs.TabPane key="security" tab="Security">
                            <ProviderList key={`apl-${org.id}`} accountId={org.id} />
                            <AccountMfaSettings key={`mfa-${org.id}`} />
                        </Tabs.TabPane>
                    )}
                </Tabs>
            </div>
        </div>
    )
}
