import {
    DisabledPromptReferrerConditions,
    EnabledPromptReferrerConditions,
    GetAccountResponse,
    GlobalPromptSettings,
} from '@pushly/models/lib/structs'
import type { AccountDto } from '../dtos/account.dto'
import { AccountMfaConfig } from '@pushly/models/lib/structs/accounts'
import { MfaMode } from '@pushly/models/lib/enums'

export function getAccountResponseFromAccountDto(dto: AccountDto): GetAccountResponse {
    return GetAccountResponse.from({
        id: dto.id,
        name: dto.name,
        status: dto.status,
        status_id: dto.statusId,
        flags: dto.flags,
        timezone: dto.timezone,
        vapid_public_key: dto.vapidPublicKey,
        media_quality: dto.mediaQuality,
        utm_params: dto.utmParams,
        keywords: dto.keywords,
        collapse_key_type: dto.collapseKeyType,
        routing_key: null,
        es_cluster_name: dto.esClusterName,
        default_notification_ttl_seconds: dto.defaultNotificationTtlSeconds,
        default_notification_landing_url: dto.defaultNotificationLandingUrl,
        added_notification_query_params: dto.addedNotificationQueryParams,
        global_prompt_settings: dto.globalPromptSettings
            ? GlobalPromptSettings.from({
                  referrers: Object.keys(dto.globalPromptSettings.referrers).length
                      ? EnabledPromptReferrerConditions.from(dto.globalPromptSettings.referrers)
                      : new DisabledPromptReferrerConditions(),
              })
            : null,
        url_profile_properties: dto.urlProfileProperties,
        frequency_caps: dto.frequencyCaps,
        billing_data: dto.billingData,
        mfa_config: AccountMfaConfig.from({
            mode: dto.mfaConfig?.mode ?? MfaMode.ADAPTIVE,
            remember_browser: dto.mfaConfig?.rememberBrowser ?? true,
        }),
        platform_whitelabel_id: parseInt(dto.platformWhitelabelId, 10),
        auth0_organization_id: dto.auth0OrganizationId,
        auth0_organization_name: dto.auth0OrganizationName,
        display_meta: dto.displayMeta,
        domains: dto.domains,
        created_at: String(dto.createdAt),
        created_by: dto.createdBy,
        created_by_user_name: dto.createdByUserName,
        updated_at: String(dto.updatedAt),
        updated_by: dto.updatedBy,
        updated_by_user_name: dto.updatedByUserName,
    })
}
