import type { GetAccountResponse } from '@pushly/models/lib/structs'
import { useEffect, useState } from 'react'
import { useService } from '@pushly/aqe/lib/hooks/use-service'
import { AccountService } from '../services'
import { getAccountResponseFromAccountDto } from '../models/account-helpers'

export type UseOrgResult =
    | {
          loading: true
          org: null
      }
    | {
          loading: false
          org: GetAccountResponse
      }

export const useOrg = (orgId: number | null): UseOrgResult => {
    const accountSvc = useService(AccountService)

    const [loading, setLoading] = useState(true)
    const [org, setOrg] = useState<GetAccountResponse | null>(null)

    useEffect(() => {
        const loadOrg = async (id: number) => {
            setLoading(true)
            setOrg(null)

            const nextOrg = await accountSvc.fetchById(id)
            setOrg(getAccountResponseFromAccountDto(nextOrg))
            setLoading(false)
        }

        if (orgId !== null && orgId !== org?.id) {
            loadOrg(orgId)
        }
    }, [orgId])

    return {
        loading,
        org,
    } as UseOrgResult
}
