import React, { type FC, useCallback } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { observer } from 'mobx-react'
import classnames from 'classnames'
import { AppState } from '../../stores/app'
import { ModuleLoadingScreen } from '../../components/module-loading-screen/module-loading-screen'
import { getPathClassName } from '../../_utils/get-path-classname'
import { Route, Switch } from '../../components/router'
import { Account } from '../post-auth/account'
import OrgNotificationSend from './org-notification-send'
import OrgNotification from './org-notification'
import Notifications from '../post-auth/notifications'
import Segments from '../post-auth/segments'
import Segment from '../post-auth/segment'
import { AbilityAction } from '../../enums/ability-action.enum'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { ICaslSubject } from '../../stores/app-ability'
import { AccountDto } from '../../dtos/account.dto'
import { buildComponentCheck } from '../../_utils/routing'
import { useService } from '@pushly/aqe/lib/hooks/use-service'
import { useRouteOrg } from '../../hooks/use-route-org'
import { OrgContext } from '../../contexts/org-context'
import { FEAT_MULTI_DOMAIN_NOTIFICATIONS } from '../../constants'
import { PageHeader } from '../../components/page-header/page-header'

export const OrganizationsFeature: FC = observer(() => {
    const appState = useService(AppState)
    const { abilityStore } = appState

    const [loading, orgId, org] = useRouteOrg()

    const loc = useLocation()
    const pathClassName = getPathClassName(loc.pathname)
    const { path: matchedPath } = useRouteMatch<{ path: string }>()

    const ensureOrgHasMultiDomainNotifications = useCallback(() => {
        return org?.flags.includes(FEAT_MULTI_DOMAIN_NOTIFICATIONS) ?? false
    }, [org?.id])

    const accessCheck = <T extends SubjectEntity>(action: AbilityAction, check: T | ICaslSubject<T> | AccountDto) => {
        const isOrgCheck =
            check instanceof AccountDto ||
            check === SubjectEntity.ORG ||
            (typeof check === 'object' && check.hasOwnProperty('__caslSubjectType__'))
        const entity = isOrgCheck ? check : abilityStore.getOrgOwnedIdentityFor(check as T)

        return abilityStore.can(action, entity as ICaslSubject<T> | AccountDto)
    }

    return (
        <div className={classnames('module', 'organizations', pathClassName)}>
            <ModuleLoadingScreen loading={loading || undefined} />

            {loading ? (
                <div className="sw-page single-account">
                    <div className="sw-page-wrapper">
                        <PageHeader title="Organization" append={<span>ID: {orgId}</span>} loading={true} />
                    </div>
                </div>
            ) : (
                <OrgContext.Provider value={{ org }}>
                    <Switch>
                        <Route
                            path={matchedPath}
                            exact={true}
                            component={buildComponentCheck(Account, [
                                [() => accessCheck(AbilityAction.UPDATE, abilityStore.currentOrgIdentity)],
                            ])}
                        />

                        <Route
                            path={`${matchedPath}/notifications`}
                            exact={true}
                            component={buildComponentCheck(Notifications, [
                                [() => accessCheck(AbilityAction.READ, SubjectEntity.ORG_NOTIFICATION)],
                                [ensureOrgHasMultiDomainNotifications],
                            ])}
                        />
                        <Route
                            path={`${matchedPath}/notifications/new`}
                            exact={true}
                            component={buildComponentCheck(OrgNotificationSend, [
                                [() => accessCheck(AbilityAction.CREATE, SubjectEntity.ORG_NOTIFICATION)],
                                [ensureOrgHasMultiDomainNotifications],
                            ])}
                        />
                        <Route
                            path={`${matchedPath}/notifications/:notificationId([0-9]+)/edit`}
                            exact={true}
                            component={buildComponentCheck(OrgNotificationSend, [
                                [() => accessCheck(AbilityAction.UPDATE, SubjectEntity.ORG_NOTIFICATION)],
                                [ensureOrgHasMultiDomainNotifications],
                            ])}
                        />
                        {/* "/notifications" path catch all - do not put any "/notifications/id..." paths beneath this */}
                        <Route
                            path={`${matchedPath}/notifications/:notificationId([0-9]+)`}
                            exact={false}
                            component={buildComponentCheck(OrgNotification, [
                                [() => accessCheck(AbilityAction.READ, SubjectEntity.ORG_NOTIFICATION)],
                                [ensureOrgHasMultiDomainNotifications],
                            ])}
                        />

                        {/*
                     Segments will not have a separate flag for multi-domain level.
                     If an org has access to multi-domain notifications they will
                     also implicitly have access to segmentation.
                     */}
                        <Route
                            path={`${matchedPath}/segments`}
                            exact={true}
                            component={buildComponentCheck(Segments, [
                                [() => accessCheck(AbilityAction.READ, SubjectEntity.ORG_SEGMENT)],
                                [ensureOrgHasMultiDomainNotifications],
                            ])}
                        />
                        <Route
                            path={`${matchedPath}/segments/new`}
                            exact={true}
                            component={buildComponentCheck(Segment, [
                                [() => accessCheck(AbilityAction.CREATE, SubjectEntity.ORG_SEGMENT)],
                                [ensureOrgHasMultiDomainNotifications],
                            ])}
                        />
                        {/* "/segments" path catch all - do not put any "/segments/id..." paths beneath this */}
                        <Route
                            path={`${matchedPath}/segments/:segmentId([0-9]+)`}
                            exact={false}
                            component={buildComponentCheck(Segment, [
                                [() => accessCheck(AbilityAction.UPDATE, SubjectEntity.ORG_SEGMENT)],
                                [ensureOrgHasMultiDomainNotifications],
                            ])}
                        />
                    </Switch>
                </OrgContext.Provider>
            )}
        </div>
    )
})
