import React, { useCallback, useState } from 'react'
import './account-mfa-settings.scss'
import { Button, Drawer, Well } from '@pushly/aqe/lib/components'
import { EditOutlined } from '@ant-design/icons'
import { AccountMfaRememberBrowserControl, AccountMfaModeControl } from '@pushly/aqe/lib/components'
import { useService } from '@pushly/aqe/lib/hooks/use-service'
import { AccountService } from '../../services'
import { AccountMfaConfig, UpdateAccountRequest } from '@pushly/models/lib/structs/accounts'
import { getAccountResponseFromAccountDto } from '../../models/account-helpers'
import { useOrgContextWithReducer } from '../../contexts/org-context'

type AccountMfaSettingsProps = {}

export const AccountMfaSettings: React.FC<AccountMfaSettingsProps> = () => {
    const orgSvc = useService(AccountService)

    const [{ org }, dispatch] = useOrgContextWithReducer()
    const { id: orgId } = org

    const [editorVisible, setEditorVisible] = useState(false)
    const [editorValue, setEditorValue] = useState(() => org.mfa_config)

    const resetValues = (nextValue: AccountMfaConfig) => {
        dispatch({ type: 'patch_mfa', value: nextValue })
        setEditorValue(nextValue)
    }

    const updateSettings = useCallback(async () => {
        const req = new UpdateAccountRequest()
        req.mfa_config = editorValue

        const res = await orgSvc.patch(orgId, req, true)
        if (res.ok) {
            const { mfa_config: nextMfaConfig } = getAccountResponseFromAccountDto(res.data)
            resetValues(nextMfaConfig)
            setEditorVisible(false)
        }
    }, Object.values(editorValue.serialize()))

    const hasChanges = !editorValue.equals(org.mfa_config)

    return (
        <Well
            title="Multi-Factor Authentication (MFA)"
            className="account-mfa-settings type-3"
            hideFooter={true}
            actions={
                <Button size="small" onClick={() => setEditorVisible(true)}>
                    <EditOutlined />
                    <span>Edit</span>
                </Button>
            }
        >
            <AccountMfaRememberBrowserControl value={org.mfa_config.remember_browser} readonly={true} />
            <AccountMfaModeControl value={org.mfa_config.mode} readonly={true} />

            <Drawer
                className="account-mfa-settings-editor"
                title="Edit MFA Settings"
                visible={editorVisible}
                onClose={() => {
                    setEditorVisible(false)
                    setEditorValue(org.mfa_config)
                }}
                destroyOnClose={true}
                onSubmit={() => updateSettings()}
                disableSubmit={!hasChanges}
            >
                <AccountMfaRememberBrowserControl
                    value={editorValue.remember_browser}
                    onChange={(remember_browser) => {
                        setEditorValue(editorValue.clone({ remember_browser }))
                    }}
                />
                <br />
                <AccountMfaModeControl
                    value={editorValue.mode}
                    onChange={(mode) => {
                        setEditorValue(editorValue.clone({ mode }))
                    }}
                />
            </Drawer>
        </Well>
    )
}
