import * as React from 'react'
import { Well, Button, executeCleanDrawerRender, Drawer } from '@pushly/aqe/lib/components'
import { Form, Skeleton, Select, Switch } from 'antd'
import moment from 'moment-timezone'
import { getClassNames } from '../../_utils/classnames'
import { AccountDto } from '../../dtos/account.dto'
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons'
import randomstring from 'randomstring'
import { AccountService, AppService } from '../../services/index'
import { AppState } from '../../stores/app'
import { Container } from 'typescript-ioc'
import { FormInstance } from 'antd/es/form'
import { DEFAULT_DRAWER_FORM_ITEM_LAYOUT } from '../../constants'
import { getSupportedTimezones } from '../../_utils/moment'
import TemplatizedTextField from '../templatized-text-field/templatized-text-field'
import deepEqual from 'react-fast-compare'
import { simpleFormErrorNotification } from '../../_utils/utils'

interface IAccountDetailsEditor {
    account: AccountDto
    visible: boolean
    onClose: (e: any) => void
    onAccountUpdated: (account: Partial<AccountDto>) => void
}

interface IADEState {
    urlEnabled: boolean
}

class AccountDetailsEditor extends React.Component<IAccountDetailsEditor, IADEState> {
    public state: IADEState = {
        urlEnabled: this.props.account.defaultNotificationLandingUrl ? true : false,
    }

    protected appState: AppState
    protected appService: AppService
    protected accountService: AccountService

    protected formRef = React.createRef<FormInstance>()

    public constructor(props: IAccountDetailsEditor) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.accountService = Container.get(AccountService)

        executeCleanDrawerRender(this)
    }

    public render() {
        const defaultTz = this.props.account.timezone ?? moment.tz.guess()
        const tzOptions = getSupportedTimezones()

        const defaultLandingUrl = this.props.account.defaultNotificationLandingUrl

        return (
            <Drawer
                getContainer={this.appService.getAppContainer}
                className={getClassNames('adf-details-editor')}
                title="Update Organization Details"
                placement="right"
                closable={true}
                onClose={this.handleDrawerClose}
                onSubmit={this.handleSubmit}
                visible={this.props.visible}
            >
                <Form
                    {...DEFAULT_DRAWER_FORM_ITEM_LAYOUT}
                    className="hide-inline-errors"
                    ref={this.formRef}
                    name="details-settings"
                >
                    <Well title="Organization Information" mode="ghost" hideFooter={true}>
                        <Form.Item
                            label="Default Time Zone"
                            className={getClassNames('details-editor-els')}
                            name="timezone"
                            initialValue={defaultTz}
                        >
                            <div className={getClassNames('form-layout-row')}>
                                <div className={getClassNames('form-layout-row-value')}>
                                    <div className="delivery-tz-picker">
                                        <Select
                                            size="small"
                                            className="delivery-tz-picker-set"
                                            showSearch={true}
                                            placeholder="Select a time zone"
                                            onChange={(value) => {
                                                this.formRef.current?.setFieldsValue({ timezone: value })
                                            }}
                                            defaultValue={defaultTz}
                                        >
                                            {tzOptions.map((tz) => (
                                                <Select.Option key={tz} value={tz}>
                                                    {tz}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </Form.Item>

                        <Form.Item
                            label="Default Landing URL"
                            className={getClassNames('details-editor-els')}
                            name="defaultNotificationLandingUrl"
                            initialValue={defaultLandingUrl}
                        >
                            <div className={getClassNames('form-layout-row')}>
                                <div className={getClassNames('form-layout-row-value')}>
                                    <div className="switch-row segment-settings-adhoc-switch">
                                        <span className="switch-left">
                                            <Switch
                                                size="small"
                                                defaultChecked={this.state.urlEnabled}
                                                onChange={async () => {
                                                    await this.setState({ urlEnabled: !this.state.urlEnabled })
                                                }}
                                            />
                                        </span>
                                        <span className="switch-right">
                                            {this.state.urlEnabled && (
                                                <TemplatizedTextField
                                                    size="small"
                                                    hideEmojis={true}
                                                    macroTypes={['domain']}
                                                    value={this.formRef.current?.getFieldValue(
                                                        'defaultNotificationLandingUrl',
                                                    )}
                                                />
                                            )}
                                        </span>
                                    </div>
                                </div>

                                <div className={getClassNames('form-layout-row-sub')}>
                                    <InfoCircleOutlined />
                                    <span>
                                        Setting a default landing URL will automatically fill this value in the “Landing
                                        URL” field during organization notification creation.
                                    </span>
                                </div>
                            </div>
                        </Form.Item>
                    </Well>
                </Form>
            </Drawer>
        )
    }

    protected get account(): AccountDto {
        return this.props.account
    }

    protected getCurrentValues(): Partial<AccountDto> {
        const values: Partial<AccountDto> = {}
        const formValues = this.formRef.current?.getFieldsValue()

        // Account Details
        if (!!formValues.timezone) {
            if (!deepEqual(formValues.timezone, this.account.timezone)) {
                values.timezone = formValues.timezone
            }
        }

        // Landing URL disabled = null, else value entered
        if (!this.state.urlEnabled) {
            formValues.defaultNotificationLandingUrl = null
        }

        if (!deepEqual(formValues.defaultNotificationLandingUrl, this.account.defaultNotificationLandingUrl)) {
            values.defaultNotificationLandingUrl = formValues.defaultNotificationLandingUrl
        }

        return values
    }

    protected handleSubmit = async () => {
        try {
            const mergedValues = this.getCurrentValues()

            const { ok, data } = await this.accountService.patch(this.account.id, mergedValues, true)

            if (ok) {
                this.handleDrawerClose()
                this.props.onAccountUpdated(data)
            }
        } catch (err) {
            simpleFormErrorNotification(err)
        }
    }

    protected handleDrawerClose = async () => {
        this.props.onClose(null)
    }
}

interface IAccountDetailsWell {
    loading?: boolean
    account: AccountDto
    title?: string
    onAccountUpdated: (account: AccountDto) => void
}

interface IADWState {
    showDrawer: boolean
    drawerKey?: string
}

export class AccountDetailsWell extends React.Component<IAccountDetailsWell, IADWState> {
    public state: IADWState = {
        showDrawer: false,
    }

    protected appState: AppState

    public constructor(props: IAccountDetailsWell) {
        super(props)

        this.appState = Container.get(AppState)
    }

    public render() {
        return (
            <Well
                className="nested type-3"
                title="Details"
                hideFooter={true}
                actions={
                    <Button size="small" onClick={this.handleDrawerOpen} disabled={this.props.loading}>
                        <EditOutlined />
                        <span>Edit</span>
                    </Button>
                }
            >
                <Skeleton loading={this.props.loading} active={true} title={false}>
                    {!this.props.loading && (
                        <>
                            {this.renderInformationWell()}

                            <AccountDetailsEditor
                                key={this.state.drawerKey}
                                visible={this.state.showDrawer}
                                account={this.account}
                                onClose={this.handleDrawerClose}
                                onAccountUpdated={this.props.onAccountUpdated}
                            />
                        </>
                    )}
                </Skeleton>
            </Well>
        )
    }

    protected get account(): AccountDto {
        return this.props.account
    }

    protected handleDrawerOpen = async () => {
        return this.setState({ showDrawer: true, drawerKey: randomstring.generate() })
    }

    protected handleDrawerClose = async () => {
        return this.setState({ showDrawer: false })
    }

    protected renderInformationWell() {
        return (
            <Well title="Organization Information" mode="ghost" hideFooter={true}>
                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>Org Name</div>
                    <div className={getClassNames('form-layout-row-value')}>{this.props.account.name}</div>
                </div>

                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>Default Timezone</div>
                    <div className={getClassNames('form-layout-row-value')}>
                        {this.props.account.timezone || '(Not Set)'}
                    </div>
                </div>

                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>Default Landing URL</div>
                    <div className={getClassNames('form-layout-row-value')}>
                        {this.props.account.defaultNotificationLandingUrl || '(Not Set)'}
                    </div>
                </div>
            </Well>
        )
    }
}
