import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

type OrgRouteParams = {
    orgId: string
    // legacy param name
    accountId: string
}

export const useRouteOrgId = (onChange?: () => void) => {
    const params = useParams<OrgRouteParams>()

    const nextValue = params.orgId ?? params.accountId ? parseInt(params.orgId ?? params.accountId, 10) : null

    if (nextValue === null) {
        throw new Error('Component requires orgId in path')
    }

    const [value, setValue] = useState(nextValue)

    useEffect(() => {
        if (nextValue !== value) {
            setValue(nextValue)
            onChange?.()
        }
    }, [nextValue])

    return value
}
