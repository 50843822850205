import type { GetAccountResponse } from '@pushly/models/lib/structs'
import { useEffect, useState } from 'react'
import { useRouteOrgId } from './use-route-org-id'
import { useOrg } from './use-org'

type UseRouteOrgResult =
    // loading state
    | [true, number, null]
    // loaded state
    | [false, number, GetAccountResponse]

export const useRouteOrg = (): UseRouteOrgResult => {
    const nextOrgId = useRouteOrgId()
    const [currOrgId, setCurrOrgId] = useState(nextOrgId)

    useEffect(() => {
        if (nextOrgId !== currOrgId) {
            setCurrOrgId(nextOrgId)
        }
    }, [nextOrgId, currOrgId])

    const { loading, org } = useOrg(currOrgId)

    return [loading, currOrgId, org] as UseRouteOrgResult
}
